import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Hidden,
  Divider,
} from "@material-ui/core";
import SportsFootball from "@material-ui/icons/SportsFootball";
import SportsBasketball from "@material-ui/icons/SportsBasketball";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot,
} from "@material-ui/lab";

import { Panel } from "../../shared/components";
import { useStyles } from "./styles";

const descriptionItems = [
  {
    icon: <SportsFootball color="primary" />,
    title: "Fans want to support sports.",
    descriptionPart1:
      "The Crawford High School Cross Country Team raised $9,000 from Crawford Alumni for running shoes, entry fees, and travel expenses – With InWin, you can help kids earn more donations by excelling at their sport!",
    descriptionPart2:
      "Fans of the Jamaican bobsled team raised $120,000 to help them go to the Olympics – Help underfunded athletes realize their dreams by encouraging their performance!",
  },
  {
    icon: <SportsBasketball color="primary" />,
    title: "The Gamification of Giving.",
    descriptionPart1:
      "Nothing but Nets: Stephen Curry sends three malaria-preventing mosquito nets to Africa for every 3-pointer he makes — You can pledge a net for every three pointer too!",
    descriptionPart2:
      "LeBron James spends at least $1M every year on educational initiatives; he also provides 1,100 kids from his home-town of Akron, OH with college scholarships, totaling $41.8 M — Support LeBron’s efforts by pledging money to these causes based on his performance. Donate $1 for every Lakers win, every point he scores, or every assist!",
  },
];

export const WhyItWorks = (props: RouteComponentProps) => {
  const styles = useStyles();

  return (
    <Box textAlign="center" pt={2}>
      <Grid container alignItems="center" className={styles.grid}>
        <Panel
          title="InWin: Why It Works!"
          description="Result-based crowdfunding. Support your idols’ favorite charity through their performance."
        >
          <Grid container alignItems="center" justify="center">
            <Hidden mdUp>
              {descriptionItems.map((item) => (
                <Card key={item.title} className={styles.card} elevation={3}>
                  <CardHeader
                    avatar={item.icon}
                    title={<Typography>{item.title}</Typography>}
                    about="Hello"
                  ></CardHeader>
                  <CardContent>
                    <Typography>{item.descriptionPart1}</Typography>
                    <Box py={1}>
                      <Divider />
                    </Box>
                    <Typography>{item.descriptionPart2}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Hidden>
            <Hidden smDown>
              <Timeline align="alternate">
                {descriptionItems.map((item) => (
                  <TimelineItem key={item.title}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" variant="outlined">
                        {item.icon}
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={3} className={styles.paper}>
                        <Typography variant="h6" component="h1">
                          {item.title}
                        </Typography>
                        <Typography>{item.descriptionPart1}</Typography>
                        <Box py={1}>
                          <Divider />
                        </Box>
                        <Typography>{item.descriptionPart2}</Typography>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Hidden>
          </Grid>
        </Panel>
      </Grid>
    </Box>
  );
};
