import axios from "axios";

const pickRandom = (arr: any, count: number) => {
  let _arr = [...arr];
  return [...Array(count)].map(
    () => _arr.splice(Math.floor(Math.random() * _arr.length), 1)[0]
  );
};

/**
 * Get info for Beneficiaries
 * 1. Pick random 5
 * 2. Send 3 requests per one Beneficiary
 * 3. Format data and return array
 */
export const getStatisticBeneficiaries = async () => {
  const { data: beneficiaries } = await axios.get(
    "/statistics/member/beneficiaries"
  );

  const pickedBeneficiaries = pickRandom(beneficiaries.content, 5);

  const requestsWithMoney = (id: number) => [
    axios.get(`/statistics/beneficiaryMoneyStat/${id}`, {
      params: {
        timeFrame: "LAST_MONTH",
      },
    }),
    axios.get(`/statistics/beneficiaryMoneyStat/${id}`, {
      params: {
        timeFrame: "LAST_YEAR",
      },
    }),
    axios.get(`/statistics/beneficiaryMoneyStat/${id}`),
  ];

  const beneficiariesMoneyRequests = pickedBeneficiaries.map(({ id }) =>
    requestsWithMoney(id)
  );

  interface ICombinedMoneyResults {
    [key: string]: any;
  }

  let combinedMoneyResults: ICombinedMoneyResults = {};
  for (let i = 0; i < pickedBeneficiaries.length; i++) {
    const dataPerBeneficiary = await axios.all(beneficiariesMoneyRequests[i]);

    combinedMoneyResults[pickedBeneficiaries[i].id] = dataPerBeneficiary.map(
      ({ data }) => data
    );
  }

  return pickedBeneficiaries.map((statB, index) => ({
    id: statB.id,
    name: statB.beneficiaryName,
    logo: `${statB.logo}`,
    href: statB.website,
    lastMonth: combinedMoneyResults[statB.id][0],
    lastYear: combinedMoneyResults[statB.id][1],
    allTime: combinedMoneyResults[statB.id][2],
  }));
};

/**
 * Get info for Tournaments by type
 * @param type "nba" | "nhl" | "mlb"
 */
export const getStatisticTournament = async (type: "nba" | "nhl" | "mlb") => {
  const matches = [
    axios.get(`statistics/matches/${type}`, {
      params: {
        seasonYear: "2024",
      },
    }),
    axios.get(`statistics/matches/${type}`),
  ];

  const matchesResponse = await axios.all(matches);

  const formattedData = matchesResponse.map(({ data }) => data);
  return {
    seasonMatches: formattedData[0],
    allMatches: formattedData[1],
  };
};

/**
 * Get info for Sponsors
 * only first sponsor
 */
export const getStatisticSponsors = async () => {
  const { data } = await axios.get(`/sponsor/sponsors`);

  const firstSponsor = data.content[0];
  return {
    id: firstSponsor?.id,
    fullName: firstSponsor?.fullName,
    logo: firstSponsor?.logo,
    allTime: firstSponsor?.donatedAllTime,
    lastMonth: firstSponsor?.donatedLastMonth,
    lastYear: firstSponsor?.donatedLastYear,
    website: firstSponsor?.website,
  };
};
