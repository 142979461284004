import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import {INWIN_BENE_SIGNUP_URL} from "config";

export const BecomeBeneficiaryCard = () => {
  const styles = useStyles();

  return (
    <Grid item xs={2} className={styles.cardBeneficiary}>
      <Card elevation={3}>
        <Link
          color={"inherit"}
          underline={"none"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={INWIN_BENE_SIGNUP_URL}
        >
          <CardActionArea className={styles.cardActionArea}>
            <CardContent>
              <Box textAlign={"left"}>
                <Typography variant="body1">
                  <strong>Apply to become a Beneficiary</strong>
                </Typography>
                <Typography variant={"body2"}>
                  and start receiving donations!
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
};
