import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Avatar,
  Grid,
  Card,
  CardHeader,
  Typography,
  Divider,
} from "@material-ui/core";

import { Panel } from "../../shared/components";
import { useStyles } from "./styles";

const fansDescriptionData = [
  {
    description: "Deepen emotional involvement",
  },
  {
    description:
      "Influence your idols, the stars of sports, and worthy initiatives",
  },
  {
    description: "Become an integral part of victory – and brag about it!",
  },
];

const profiDescriptionData = [
  {
    description: "Tap additional sources of financing and remuneration",
  },
  {
    description:
      "Quantify engagement for specific events, charities, and sports",
  },
  {
    description: "Improve PR",
  },
];

export const NeedsDrivers = (props: RouteComponentProps) => {
  const styles = useStyles();

  const renderDescription = (
    descItem: typeof fansDescriptionData[0],
    idx: number
  ) => (
    <Card key={descItem.description} className={styles.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="fans" className={styles.avatar}>
            {`0${idx + 1}`}
          </Avatar>
        }
        title={<Typography variant="body1">{descItem.description}</Typography>}
      />
    </Card>
  );

  return (
    <Box textAlign="center" pt={3}>
      <Grid container justify="center" className={styles.grid}>
        <Panel title="InWin: Needs And Drivers">
          <Grid container alignItems="center" justify="center">
            <Grid item xs={12} md={6}>
              <Box pt={4}>
                <Typography variant="h4" color="textSecondary">
                  Sports Fans
                </Typography>
                <Box px={4} py={1}>
                  <Divider />
                </Box>
                {fansDescriptionData.map(renderDescription)}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box pt={4}>
                <Typography variant="h4" color="textSecondary">
                  Sports Professionals
                </Typography>
                <Box px={4} py={1}>
                  <Divider />
                </Box>
                {profiDescriptionData.map(renderDescription)}
              </Box>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Box>
  );
};
