import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1, 2),
      textAlign: "left",
    },

    card: {
      margin: theme.spacing(2),
      textAlign: "left",
    },

    grid: {
      height: "inherit",
      minHeight: "inherit",
    },

    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);
