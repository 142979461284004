import React from "react";
import { IBeneficiaryProps } from "./IBeneficiaryProps";
import { DefaultBeneficiaryCard } from "./defaultBeneficiary";
import { BecomeBeneficiaryCard } from "./becomeBeneficiary";

export const BeneficiaryCard = ({
  isPlaceholder,
  beneficiary,
}: IBeneficiaryProps) => {
  return isPlaceholder ? (
    <BecomeBeneficiaryCard />
  ) : (
    <DefaultBeneficiaryCard beneficiary={beneficiary} />
  );
};
