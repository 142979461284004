const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (value: string) => EMAIL_PATTERN.test(value);

export const createEmailFieldErrorMsg = (
  type: string | undefined
): string | null => {
  if (type === undefined) {
    return null;
  }

  switch (type) {
    case "required":
      return "Email is required";
    case "maxLength":
      return "Email address can not have more than 255 characters";
    case "email":
      return "Email address is not valid";
    default:
      return "Email is not valid";
  }
};

export const createFirstNameFieldErrorMsg = (
  type: string | undefined
): string | null => {
  if (type === undefined) {
    return null;
  }

  switch (type) {
    case "required":
      return "First name is required";
    case "maxLength":
      return "First name can not have more than 50 characters";
    default:
      return "First name is not valid";
  }
};

export const createLastNameFieldErrorMsg = (
  type: string | undefined
): string | null => {
  if (type === undefined) {
    return null;
  }

  switch (type) {
    case "maxLength":
      return "Last name can not have more than 50 characters";
    default:
      return "Last name is not valid";
  }
};

export const createActivityNameFieldErrorMsg = (
  type: string | undefined
): string | null => {
  if (type === undefined) {
    return null;
  }

  switch (type) {
    case "maxLength":
      return "Activity interest explanation can not have more than 1000 characters";
    default:
      return "Activity interest is not valid";
  }
};
