import React from "react";
import { Link } from "@reach/router";
import {
  useTheme,
  useMediaQuery,
  AppBar,
  Drawer,
  Hidden,
  Box,
  Toolbar,
  IconButton,
  Fab,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import TouchAppIcon from "@material-ui/icons/TouchApp";

import * as routes from "routeMap";
import { INWIN_PORTAL_SIGNUP_URL } from "config";
import { AnimatedRouter, Logo } from "view/shared/components";

import { WhatIsIt } from "../whatisit";
import { HowItWorks } from "../howitworks";
import { WhyItWorks } from "../whyitworks";
import { Beneficiaries } from "../beneficiaries";

import { NeedsDrivers } from "../needsdrivers";
import { links } from "./links";
import { useStyles } from "./styles";
import { Footer } from "../footer";

export const Main = () => {
  const theme = useTheme();
  const styles = useStyles();

  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const xsmall = useMediaQuery(theme.breakpoints.down("xs"));

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = document.body;

  const openDrawer = () => {
    setMobileOpen(true);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const getLinkProps = ({ isCurrent }: { isCurrent: boolean }) => {
    return {
      style: {
        color: isCurrent ? theme.palette.primary.dark : "inherit",
      },
    };
  };

  const drawer = (
    <div>
      <Hidden xsDown implementation="css">
        <Toolbar />
      </Hidden>
      <List>
        {links.map(({ url, title }) => (
          <ListItem
            component={Link}
            getProps={getLinkProps}
            onClick={closeDrawer}
            to={url}
            button
            key={url}
          >
            <ListItemText primary={title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" color="inherit" className={styles.appBar}>
        <Toolbar>
          <Box className={styles.toolbarBox}>
            <Typography variant="h6" noWrap>
              <Box className={styles.box}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={openDrawer}
                  className={styles.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Logo size="sm" />
                <Box mx={1}>
                  <Typography
                    variant={small ? (xsmall ? "body2" : "h6") : "h5"}
                  >
                    InWin
                  </Typography>
                </Box>
              </Box>
            </Typography>
            <Hidden mdUp>
              <Fab
                title="Stay in touch"
                color="primary"
                size="small"
                aria-label="Keep in touch fab"
                href={INWIN_PORTAL_SIGNUP_URL}
              >
                <TouchAppIcon />
              </Fab>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      <div className={styles.root}>
        <nav className={styles.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={closeDrawer}
              classes={{
                paper: styles.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: styles.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={styles.content}>
          <Toolbar />
          <AnimatedRouter>
            <Beneficiaries default />
            <WhatIsIt path={routes.whatIsIt} />
            <HowItWorks path={routes.howItWorks} />
            <WhyItWorks path={routes.whyItWorks} />
            <NeedsDrivers path={routes.needsDrivers} />
          </AnimatedRouter>
        </main>
      </div>
      <Footer />
    </>
  );
};
