import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMediaTour: {
      height: theme.spacing(11),
      width: theme.spacing(20),
      backgroundSize: "contain",
    },

    cardTournaments: {
      minHeight: theme.spacing(16),
      [theme.breakpoints.up("md")]: {
        minWidth: theme.spacing(51),
      },
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(2),
    },
    cardActionAreaBox: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
  })
);
