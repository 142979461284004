import { makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: "100%", // Fix IE 11 issue.
    },

    inputStyle: {
      height: theme.spacing(6),
    },

    actionsContainer: {
      padding: theme.spacing(1, 3, 1, 3),
    },

    submit: {
      margin: theme.spacing(3, 0, 2),
    },

    hidden: {
      display: "none",
    },
  })
);
