import React from "react";
import { Avatar } from "@material-ui/core";

import { ILogoProps } from "./types/ILogoProps";
import { useStyles } from "./styles";

export const Logo = (props: ILogoProps) => {
  const styles = useStyles(props);

  return (
    <Avatar
      src="/images/big_logo.png"
      alt="InWin: Influence. Win."
      className={styles.root}
      imgProps={{ className: styles.img }}
    />
  );
};
