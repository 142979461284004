import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      margin: theme.spacing(2),
    },

    avatar: {
      backgroundColor: theme.palette.primary.main,
    },

    grid: {
      height: "inherit",
      minHeight: "inherit",
    },
  })
);
