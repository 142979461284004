import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Grid,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Divider,
} from "@material-ui/core";
import People from "@material-ui/icons/People";
import NaturePeople from "@material-ui/icons/NaturePeople";
import classnames from "classnames";

import { Panel } from "../../shared/components";
import { useStyles } from "./styles";

export const HowItWorks = (props: RouteComponentProps & { theme?: Theme }) => {
  const styles = useStyles(props);

  return (
    <>
      <Box textAlign="center" pt={3}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={12} md={8}>
            <Panel
              title="InWin: How It Works?"
              description="InWin allows fans to donate to charitable causes through the performance of their favorite athletes and teams."
            >
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Card
                    className={classnames(styles.card, styles.cardMin)}
                    elevation={3}
                  >
                    <CardHeader
                      avatar={<People color="primary" fontSize="large" />}
                      title="Engagement"
                    />
                    <CardContent>
                      <Typography>
                        Fans can pledge donations that depend on the performance
                        of athletes and teams, increasing their engagement with
                        the sport.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card
                    className={classnames(styles.card, styles.cardMin)}
                    elevation={3}
                  >
                    <CardHeader
                      avatar={<NaturePeople color="primary" fontSize="large" />}
                      title="Charity"
                    />
                    <CardContent>
                      <Typography variant="body1">
                        You can become a philanthropist benefiting preferred
                        charities of your favorite athletes or helping
                        underfinanced teams and sports.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card className={styles.card}>
                    <CardContent>
                      <Typography>
                        By placing an InWin, you can contribute to charities
                        championed by sporting stars or sponsor a club, team, or
                        athlete trying to make their dream come true.
                        <Box py={1}>
                          <Divider />
                        </Box>
                        For example, you can pledge to donate $10 to the LeBron
                        James Family Foundation for every triple-double King
                        James records.
                        <Box py={1}>
                          <Divider />
                        </Box>
                        Or, you can pledge $10 towards the renovation of your
                        school’s sports facilities for every win its teams
                        score.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Panel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
