import { makeStyles, createStyles, Theme } from "@material-ui/core";

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },

    toolbarBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },

    box: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },

    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },

    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },

    appsContainer: {
      marginTop: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 900,
    },
    appsIcon: {
      maxHeight: theme.spacing(6),
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },

    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    drawerPaper: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "transparent",
      },
      width: drawerWidth,
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginBottom: theme.spacing(8),
    },
  })
);
