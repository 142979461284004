import React from "react";
import { Card, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import { ISponsorCardProps } from "./ISponsorCardProps";
import { BecomeSponsor } from "./becomeSponsor";
import { DefaultSponsor } from "./defaultSponsor";

export const SponsorCard = ({ isPlaceholder, sponsor }: ISponsorCardProps) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} className={styles.cardSponsor}>
      <Card elevation={3}>
        {isPlaceholder ? (
          <BecomeSponsor />
        ) : (
          <DefaultSponsor sponsor={sponsor} />
        )}
      </Card>
    </Grid>
  );
};
