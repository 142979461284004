import React from "react";
import classnames from "classnames";

import { IImageProps } from "./types/IImageProps";
import { useStyles } from "./styles";

export const Image = (props: IImageProps) => {
  const { alt, name, ns, ext, className } = props;
  const styles = useStyles(props);
  let path = [ns, name].join("/");

  path = path.startsWith("/") ? path.slice(1) : path;

  return (
    <img
      className={classnames(styles.root, className)}
      alt={alt}
      src={`/images/${path}.${ext ?? "png"}`}
    ></img>
  );
};
