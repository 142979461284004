import React, { useState, useContext, createContext, ReactNode } from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import * as api from "application/inbound.api";
import { useStyles } from "./styles";
import {
  validateEmail,
  createEmailFieldErrorMsg,
  createFirstNameFieldErrorMsg,
  createLastNameFieldErrorMsg,
  createActivityNameFieldErrorMsg,
} from "./validators";
const TouchDialogContext = createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

export const TouchDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const stateHandlers = useState(false);

  return (
    <TouchDialogContext.Provider value={stateHandlers}>
      {children}
    </TouchDialogContext.Provider>
  );
};

export const TouchDialogTrigger = ({
  children,
}: {
  children(
    stateUpdater: React.Dispatch<React.SetStateAction<boolean>>
  ): JSX.Element;
}) => {
  const [, setDialogOpenState] = useContext(TouchDialogContext);
  return children(setDialogOpenState);
};

interface IFormData {
  email: string;
  first: string;
  last?: string;
  comments?: string;
  beneficiary_interest?: string;
}

export const TouchDialog = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [saved, setSaveStatus] = useState(false);
  const [open, setOpen] = useContext(TouchDialogContext);

  const styles = useStyles();
  const { register, errors, handleSubmit, reset } = useForm<IFormData>();

  const onSubmitForm = handleSubmit<IFormData>(async (data): Promise<void> => {
    await api.saveBecomeSponsorData(data);
    setSaveStatus(true);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onExited = () => {
    setSaveStatus(false);
    reset();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      onExited={onExited}
      fullScreen={fullScreen && !saved}
      aria-labelledby="form-dialog-title"
    >
      <form
        noValidate
        className={classnames(styles.form, saved && styles.hidden)}
        onSubmit={onSubmitForm}
      >
        <DialogContent>
          <TextField
            className={styles.inputStyle}
            inputRef={register({
              required: true,
              maxLength: 255,
              validate: {
                email: validateEmail,
              },
            })}
            margin="normal"
            required
            fullWidth
            inputProps={{ max: 4 }}
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            error={!!errors.email}
            helperText={createEmailFieldErrorMsg(errors.email?.type)}
          />
          <TextField
            className={styles.inputStyle}
            inputRef={register({ required: true, maxLength: 50 })}
            margin="normal"
            required
            fullWidth
            name="first"
            label="First Name"
            id="first_name"
            error={!!errors.first}
            helperText={createFirstNameFieldErrorMsg(errors.first?.type)}
          />
          <TextField
            className={styles.inputStyle}
            inputRef={register({ maxLength: 50 })}
            margin="normal"
            fullWidth
            name="last"
            label="Last Name"
            id="last_name"
            error={!!errors.last}
            helperText={createLastNameFieldErrorMsg(errors.last?.type)}
          />
          <TextField
            className={styles.inputStyle}
            inputRef={register({ maxLength: 1000 })}
            margin="normal"
            fullWidth
            name="comments"
            label="Comments"
            id="comments"
            rowsMax={6}
            multiline
            error={!!errors.comments}
            helperText={createActivityNameFieldErrorMsg(errors.comments?.type)}
          />
        </DialogContent>
        <DialogActions className={styles.actionsContainer}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Send
          </Button>
        </DialogActions>
      </form>
      {/* This is workaround because ternary operator breaks form ref bounds */}
      <Box className={classnames(!saved && styles.hidden)}>
        <DialogContent>
          <DialogContentText>
            Thank you for your interest. We'll be in touch!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
