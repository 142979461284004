import React from "react";
import {
  Toolbar,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
  Box,
} from "@material-ui/core";

import PP from "docs/InWin_PP_v1.2.pdf";
import TermsCond from "docs/InWin_T&C_v1.2.pdf";
import { useStyles } from "./styles";

export const Footer = () => {
  const styles = useStyles();
  const theme = useTheme();

  const xsmall = useMediaQuery(theme.breakpoints.down("xs"));

  const fontVariant = xsmall ? "caption" : "body2";
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer color="inherit" className={styles.appBar}>
        <Toolbar className={styles.toolbar}>
          <Box style={{ display: "flex" }}>
            <Typography
              className={styles.textBlock}
              variant={fontVariant}
              color={"inherit"}
            >
              <Link href={PP} target="_blank">
                Privacy Policy
              </Link>
            </Typography>
            {"|"}
            <Typography
              className={styles.textBlock}
              variant={fontVariant}
              color={"inherit"}
            >
              <Link href={TermsCond} target="_blank">
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
          <Typography variant={fontVariant} color={"textSecondary"}>
            &copy; InWin Corporation {currentYear}
          </Typography>
        </Toolbar>
      </footer>
    </>
  );
};
