import React from "react";
import ReactDOM from "react-dom";

import { Application } from "./view/App";
import * as serviceWorker from "./serviceWorker";

import { theme } from "./view/styles/theme";
import "./view/styles/index.css";

ReactDOM.render(<Application theme={theme} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
