import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMediaSponsor: {
      height: theme.spacing(7),
      width: theme.spacing(7),
      backgroundSize: "contain",
    },

    cardMediaPlaceholder: {
      height: theme.spacing(7),
      width: theme.spacing(7),
      backgroundSize: "contain",
    },

    cardSponsor: {
      maxWidth: theme.spacing(42),
      [theme.breakpoints.up("md")]: {
        maxHeight: theme.spacing(11),
      },
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(2),
    },
  })
);
