import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},

    grid: {
      height: "inherit",
      minHeight: "inherit",
    },

    card: {
      margin: theme.spacing(2),
    },

    smartphone: {
      width: "100%",
      height: "auto",
      minHeight: 0,
      minWidth: 0,
    },
  })
);
