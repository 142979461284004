import React from "react";
import { Box, Typography } from "@material-ui/core";

export const Panel = ({ title, description, children }: any) => {
  return (
    <Box>
      <Typography variant="h2">{title}</Typography>
      {description && <Typography variant="body1">{description}</Typography>}
      <Box>{children}</Box>
    </Box>
  );
};
