import React from "react";
import {
  Box,
  CardActionArea,
  CardMedia,
  Link,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import {INWIN_SPONSOR_SIGNUP_URL} from "config";

export const BecomeSponsor = () => {
  const styles = useStyles();

  return (
    <Link
      color={"inherit"}
      underline={"none"}
      target={"_blank"}
      rel={"noopener noreferrer"}
      href={INWIN_SPONSOR_SIGNUP_URL}
    >
      <CardActionArea className={styles.cardActionArea}>
        <Box display={"flex"}>
          <CardMedia
            className={styles.cardMediaSponsor}
            image={"/images/sponsor_placeholder.png"}
          />
          <Box
            display={"flex"}
            alignItems={"center"}
            textAlign={"left"}
            paddingLeft={2}
          >
            <Box>
              <Typography variant={"body2"}>
                <strong>Become a sponsor</strong>
              </Typography>
              <Typography variant={"body2"}>Your logo can be here.</Typography>
            </Box>
          </Box>
        </Box>
      </CardActionArea>{" "}
    </Link>
  );
};
