import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMediaSponsor: {
      height: theme.spacing(10),
      width: theme.spacing(10),
      backgroundSize: "contain",
    },

    card: {
      margin: theme.spacing(1),
    },

    cardMedia: {
      margin: theme.spacing(1),
      height: theme.spacing(15),
      backgroundSize: "contain",
    },

    cardMediaPlaceholder: {
      margin: theme.spacing(2),
      height: theme.spacing(8),
      width: theme.spacing(8),
      backgroundSize: "contain",
    },

    cardBeneficiary: {
      minWidth: theme.spacing(27.5),
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(1),
    },
  })
);
