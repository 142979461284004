import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      display: "flex",
      flexDirection: "row",
      // maxWidth: "77%",
    },

    card: {
      display: "flex",
      margin: theme.spacing(2),
      padding: theme.spacing(2),
    },

    imageCont: {
      display: "flex",
      width: "70%",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundImage: `url(images/statistics/2boys.png), linear-gradient(rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9));`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundBlendMode: "overlay",
    },

    details: {
      display: "flex",
      flexDirection: "column",
    },

    money: {
      backgroundImage: `url(images/statistics/moneyBlock.png)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      marginLeft: "-16px",
      paddingLeft: "16px",
    },

    moneyText: {
      color: "#FFFFFF",
      fontWeight: 800,
    },

    cardActionArea: {
      padding: theme.spacing(2),
    },

    cardMedia: {
      width: theme.spacing(18),
      backgroundSize: "contain",
    },
  })
);
