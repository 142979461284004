import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardMin: {
      minHeight: theme.spacing(24),
    },

    card: {
      margin: theme.spacing(2),
      textAlign: "left",
    },

    smartphone: {
      height: "80vh",
    },
  })
);
