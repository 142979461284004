import React from "react";
import { RouteComponentProps } from "@reach/router";
import {
  Box,
  Grid,
  Theme,
  Hidden,
  Card,
  CardHeader,
  Typography,
} from "@material-ui/core";
import MonetizationOn from "@material-ui/icons/MonetizationOn";

import { Image, Panel } from "../../shared/components";
import { useStyles } from "./styles";

export const WhatIsIt = (props: RouteComponentProps & { theme?: Theme }) => {
  const styles = useStyles(props);

  return (
    <Box textAlign="center" pt={3} className={styles.root}>
      <Grid container alignItems="center" className={styles.grid}>
        <Grid item xs={12} md={6}>
          <Panel
            title="InWin: What is it?"
            description="InWin is the platform for fans to affect change through the sports and athletes they love."
          >
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Card className={styles.card} elevation={2}>
                  <CardHeader
                    avatar={<MonetizationOn color="primary" fontSize="large" />}
                    title={<Typography>Donate for every win!</Typography>}
                  ></CardHeader>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card className={styles.card} elevation={2}>
                  <CardHeader
                    avatar={<MonetizationOn color="primary" fontSize="large" />}
                    title={<Typography>Donate for every shutout!</Typography>}
                  ></CardHeader>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card className={styles.card} elevation={3}>
                  <CardHeader
                    avatar={<MonetizationOn color="primary" fontSize="large" />}
                    title={
                      <Typography>
                        Donate for every goal, triple-double, touchdown, or
                        homerun!
                      </Typography>
                    }
                  ></CardHeader>
                </Card>
              </Grid>
            </Grid>
          </Panel>
        </Grid>
        <Hidden smDown>
          <Grid item md={6}>
            <Box display="flex" alignItems="center">
              <Image
                ns="background"
                name="circle"
                className={styles.smartphone}
              />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};
