import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "fixed",
      width: "100%",
      bottom: 0,
      marginTop: "calc(100% - 60px)",
      backgroundColor: "#FFFFFF",
      zIndex: theme.zIndex.drawer + 1,
    },

    toolbar: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        flexDirection: "column",
      },
    },

    textBlock: {
      marginRight: "8px",
      marginLeft: "8px",
    },
  })
);
