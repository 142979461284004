import * as routes from "routeMap";

export const links = [
  {
    title: "Influence. Win!",
    url: routes.main,
  },
  {
    title: "What is it?",
    url: routes.whatIsIt,
  },
  {
    title: "How it works",
    url: routes.howItWorks,
  },
  {
    title: "Why it works",
    url: routes.whyItWorks,
  },
  {
    title: "Needs and drivers",
    url: routes.needsDrivers,
  },
];
