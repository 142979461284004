import { makeStyles, createStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(4),
    },

    grid: {
      height: "inherit",
      minHeight: "inherit",
    },

    cardMedia: {
      height: theme.spacing(15),
      backgroundSize: "contain",
    },

    cardMediaTour: {
      height: theme.spacing(11),
      width: theme.spacing(20),
      backgroundSize: "contain",
    },

    card: {
      margin: theme.spacing(2),
    },

    cardTournaments: {
      minHeight: theme.spacing(16),
      minWidth: theme.spacing(51),
    },

    cardActionArea: {
      display: "block",
      padding: theme.spacing(2),
    },
  })
);
