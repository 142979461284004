import React from "react";
import { INWIN_PORTAL_SIGNUP_URL } from "config";
import { Box, Button, Typography, Link, Grid } from "@material-ui/core";
import { Image } from "../../shared/components";
import { useStyles } from "./styles";

export const AppsAndRegister = () => {
  const styles = useStyles();

  return (
    <Box textAlign="center" className={styles.appsContainer}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <Button variant="outlined" href={INWIN_PORTAL_SIGNUP_URL}>
            Register to become a philanthropist
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography> OR </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {/*<Link*/}
          {/*  color={"inherit"}*/}
          {/*  underline={"none"}*/}
          {/*  target={"_blank"}*/}
          {/*  rel={"noopener noreferrer"}*/}
          {/*  href={""}*/}
          {/*>*/}
          {/*  <Image ns="apps" name="appstore" className={styles.appsIcon} />*/}
          {/*</Link>*/}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Link
              color={"inherit"}
              underline={"none"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={"https://play.google.com/store/apps/details?id=com.discotech.inwin"}
            >
              <Image ns="apps" name="playstore" className={styles.appsIcon} />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
