import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { ITournamentCardProps } from "./ITournamentCardProps";

export const TournamentCard = ({
  type,
  seasonGames,
  allGames,
}: ITournamentCardProps) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} className={styles.cardTournaments}>
      <Card elevation={3}>
        <Link
          color={"inherit"}
          underline={"none"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={`https://app.inwin.online/fe/main?es=${type}`}
        >
          <CardActionArea className={styles.cardActionArea}>
            <Box className={styles.cardActionAreaBox}>
              {/*<CardMedia*/}
              {/*  className={styles.cardMediaTour}*/}
              {/*  // image={`/images/${type.toLowerCase()}_logo.png`}*/}
              {/*  // title={`${type.toUpperCase()} Events`}*/}
              {/*/>*/}
              <Box paddingLeft={1} paddingTop={1} textAlign={"left"}>
                <Typography variant={"h6"}>
                  <strong>{type}</strong>
                </Typography>
                <Typography variant={"body2"}>
                  <strong>Current season: </strong>
                  {seasonGames} games
                </Typography>
                <Typography variant={"body2"}>
                  <strong>All time: </strong>
                  {allGames} games
                </Typography>
              </Box>
            </Box>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
};
