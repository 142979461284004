import axios from "axios";

import { API_URL } from "config";

interface IBecomeSponsorData {
  email: string;
  first: string;
  last?: string;
  comments?: string;
}

export const saveBecomeSponsorData = async (
  sponsorData: IBecomeSponsorData
) => {
  await axios({
    method: "post",
    url: API_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: sponsorData,
  });
};
