import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { IBeneficiaryProps } from "./IBeneficiaryProps";
import { formattedMoneyAmount } from "../../helpers/global.helper";

export const DefaultBeneficiaryCard = ({ beneficiary }: IBeneficiaryProps) => {
  const styles = useStyles();

  return (
    <Grid item xs={2} className={styles.cardBeneficiary}>
      <Card elevation={3}>
        <Link
          color={"inherit"}
          underline={"none"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          href={beneficiary.href}
        >
          <CardActionArea>
            <CardContent>
              <Box textAlign={"left"}>
                <Typography variant="body1" noWrap>
                  <strong>{beneficiary.name}</strong>
                </Typography>
                <Typography variant={"body2"}>
                  <strong>Donated last month: </strong>
                  {`$ ${formattedMoneyAmount(
                    beneficiary.lastMonth?.totalAmount
                  )}`}
                </Typography>
                <Typography variant={"body2"}>
                  <strong>Donated last year: </strong>
                  {`$ ${formattedMoneyAmount(
                    beneficiary.lastYear?.totalAmount
                  )}`}
                </Typography>
                <Typography variant={"body2"}>
                  <strong>Donated all time: </strong>
                  {`$ ${formattedMoneyAmount(
                    beneficiary.allTime?.totalAmount
                  )}`}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
};
