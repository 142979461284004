import React from "react";
import { Fade } from "@material-ui/core";
import { Router, Location } from "@reach/router";

export const AnimatedRouter = ({ children }: any) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Fade key={location.key} in={Boolean(location.key)} timeout={1000}>
            <Router location={location}>{children}</Router>
          </Fade>
        );
      }}
    </Location>
  );
};
