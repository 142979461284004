import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F09745",
    },

    secondary: {
      main: "#E5E7EC",
    },

    divider: "#F09745",
  },
});
